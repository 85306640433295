import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { sendFiltersAdapter } from './adapters'
import axios from 'axios'
import { clientsdb, addUser, updateUser, deleteUser, productServicedb } from './fake-data'
import { createProducts, deleteProduct, getProducts, updateProduct } from './thunks'


export const getData = createAsyncThunk('productService/getData', async ({filters, data}) => {
  console.log("data: ------------> ", data)
  const cleanFilters = sendFiltersAdapter(filters)
  // Filtrar los datos usando cleanFilters
  // Filtrar los datos usando cleanFilters
  const filteredData = data.filter(client => {
    let matches = true;
    for (const key in cleanFilters) {
      if (client[key] !== undefined) {
        const clientValue = key === "prodType" ? client[key].value :  client[key].toString().toLowerCase();
        const filterValue = key === "prodType" ? cleanFilters[key].value : cleanFilters[key].toString().toLowerCase();
        
        if (!clientValue.includes(filterValue)) {
          matches = false;
          break;
        }
      } else {
        matches = false;
        break;
      }
    }
    return matches;
  });
  console.log("filteredData------->: ", filteredData)
  return filteredData
})

export const addUserAction = createAsyncThunk('productService/addUser', async (user) => {
  const newUser = addUser(user);
  return newUser;
});

export const editUserAction = createAsyncThunk('productService/editUser', async (user) => {
  // Supongamos que tienes una función `updateUser` que actualiza el usuario en la base de datos
  const updatedUser = updateUser(user);
  return updatedUser;
});

export const deleteUserAction = createAsyncThunk('productService/deleteUser', async (id) => {
  const updatedData = deleteUser(id);
  return id;
});

export const checkIfUserExists = (productCode) => {
  return productServicedb.some(item => 
    (item.productCode !== "" && item.productCode === productCode));
};


const initialState = {
  data: [],
  isPending: false,
  modal: { create: { open: false }, edit: { open: false, data: null }, delete: { open: false, data: null } }
}

export const productServiceSlice = createSlice({
  initialState,
  name: 'productService',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    },
    setOpenModal: (state, action) => {
      const { type, open, data } = action.payload
      state.modal[type].open = open
      if (type === 'edit' && open && data) {
        state.modal.edit.data = data
      }

      if (type === 'delete' && open && data) {
        state.modal.delete.data = data
      }

      if (!open) {
        state.modal.edit.data = null
        state.modal.delete.data = null
      }
    }
  },
  extraReducers: builder =>
  builder.addCase(getData.fulfilled, (state, action) => {
    //state.data = action.payload
    state.isPending = false
  })
  .addCase(getData.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getData.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(addUserAction.fulfilled, (state, action) => {
    state.data.push(action.payload);
  })
  .addCase(editUserAction.fulfilled, (state, action) => {
    const updatedUser = action.payload;
    const index = state.data.findIndex(user => user.id === updatedUser.id);
    if (index !== -1) {
      state.data[index] = updatedUser;
    }
  })
  .addCase(deleteUserAction.fulfilled, (state, action) => {
    const id = action.payload;
    state.data = state.data.filter(user => user.id !== id);
  })
  .addCase(getProducts.fulfilled, (state, action) => {
    state.data = action.payload;
  })
  .addCase(createProducts.fulfilled, (state, action) => {
    state.data.push(action.payload);
  })
  .addCase(updateProduct.fulfilled, (state, action) => {
    const index = state.data.findIndex(product => product.id === action.payload.id);
    if (index !== -1) {
      state.data[index] = action.payload; // Actualizar el producto en el store localmente
    }
  })
  .addCase(deleteProduct.fulfilled, (state, action) => {
    const id = action.payload;
  })
})

export const { setInitialData, setOpenModal } = productServiceSlice.actions

export default productServiceSlice.reducer