const BASE_URL = process.env.REACT_APP_API_URL;

// ** Auth Endpoints
export default {
  loginEndpoint: `${BASE_URL}/users/login`,
  usersEndpoint: `${BASE_URL}/users`,
  companiesEndpoint: `${BASE_URL}/companies`,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  transactionsEndpoint: `${BASE_URL}/transactions`,

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
