import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { sendFiltersAdapter } from './adapters'
import axios from 'axios'
import { data } from './fake-data'


export const getData = createAsyncThunk('customerMasterLatitude/getData', async (filters) => {
  const cleanFilters = sendFiltersAdapter(filters)
  console.log(cleanFilters)
  return data
})


const initialState = {
  data: [],
  isPending: false,
  modal: { create: { open: false }, edit: { open: false } }
}

export const customerMasterLatitudeSlice = createSlice({
  initialState,
  name: 'customerMasterLatitude',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    },
    setOpenModal: (state, action) => {
      const { type, open } = action.payload
      state.modal[type].open = open
    }
  },
  extraReducers: builder =>
  builder.addCase(getData.fulfilled, (state, action) => {
    state.data = action.payload
    state.isPending = false
  })
  .addCase(getData.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getData.rejected, (state, action) => {
    state.isPending = false
  })
})

export const { setInitialData, setOpenModal } = customerMasterLatitudeSlice.actions

export default customerMasterLatitudeSlice.reducer