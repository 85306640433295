import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { sendFiltersAdapter } from './adapters'
import axios from 'axios'
import { clientsdb, addUser, updateUser, deleteUser } from './fake-data'
import { addClient, deleteClient, editClient, getClients } from './thunks'


export const getData = createAsyncThunk('appInvoice/getData', async ({filters, data}) => {

  const cleanFilters = sendFiltersAdapter(filters)

  const filteredData = cleanFilters ? data.filter(client => {
    let matches = true;
    for (const key in cleanFilters) {
      if (client[key] !== undefined) {
        const clientValue = client[key].toString().toLowerCase();
        const filterValue = cleanFilters[key].toString().toLowerCase();
        if (!clientValue.includes(filterValue)) {
          matches = false;
          break;
        }
      } else {
        matches = false;
        break;
      }
    }
    return matches;
  }) : data;

  return filteredData
})

export const addUserAction = createAsyncThunk('appInvoice/addUser', async (user) => {
  const newUser = addUser(user);
  return newUser;
});

export const editUserAction = createAsyncThunk('appInvoice/editUser', async (user) => {
  // Supongamos que tienes una función `updateUser` que actualiza el usuario en la base de datos
  const updatedUser = updateUser(user);
  return updatedUser;
});

export const deleteUserAction = createAsyncThunk('appInvoice/deleteUser', async (id) => {
  const updatedData = deleteUser(id);
  return id;
});

export const checkIfUserExists = (dui, nit) => {
  return clientsdb.some(client => 
    (client.DUI !== "" && client.DUI === dui) || 
    (client.NIT !== "" && client.NIT === nit));
};


const initialState = {
  data: [],
  filteredData: [],
  isPending: false,
  modal: { create: { open: false }, edit: { open: false, data: null }, delete: { open: false, data: null } }
}

export const appInvoiceSlice = createSlice({
  initialState,
  name: 'appInvoice',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    },
    setOpenModal: (state, action) => {
      const { type, open, data } = action.payload
      state.modal[type].open = open
      if (type === 'edit' && open && data) {
        state.modal.edit.data = data
      }

      if (type === 'delete' && open && data) {
        state.modal.delete.data = data
      }

      if (!open) {
        state.modal.edit.data = null
        state.modal.delete.data = null
      }
    }
  },
  extraReducers: builder =>
  builder.addCase(getData.fulfilled, (state, action) => {
    state.filteredData = action.payload
    state.isPending = false
  })
  .addCase(getData.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getData.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(getClients.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getClients.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(getClients.fulfilled, (state, action) => {
    state.data = action.payload
    state.filteredData = action.payload
  })
  .addCase(addClient.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(addClient.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(addClient.fulfilled, (state, action) => {
    console.log("action.payload: ", )
    if (action?.payload?.error) {
      return
    }
    state.filteredData.push(action.payload);
  })
  .addCase(editClient.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(editClient.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(editClient.fulfilled, (state, action) => {
    console.log("action.payload.id: EDITED", action.payload)
    const index = state.data.findIndex(client => client.id === action.payload.id);
    if (index !== -1) {
      state.filteredData[index] = action.payload; // Actualizar el producto en el store localmente
    }
  })
  .addCase(deleteClient.pending, (state, action) => {
    state.isPending = true

  })
  .addCase(deleteClient.rejected, (state, action) => {
    state.isPending = true

  })
  .addCase(deleteClient.fulfilled, (state, action) => {
    state.isPending = false;
  })
  .addCase(addUserAction.fulfilled, (state, action) => {
    state.data.push(action.payload);
  })
  .addCase(editUserAction.fulfilled, (state, action) => {
    const updatedUser = action.payload;
    const index = state.data.findIndex(user => user.id === updatedUser.id);
    if (index !== -1) {
      state.data[index] = updatedUser;
    }
  })
  .addCase(deleteUserAction.fulfilled, (state, action) => {
    const id = action.payload;
    state.data = state.data.filter(user => user.id !== id);
  })
})

export const { setInitialData, setOpenModal } = appInvoiceSlice.actions

export default appInvoiceSlice.reducer