import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import toast from "react-hot-toast";

// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken.replaceAll('"', '')}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken);
              this.setRefreshToken(r.data.refreshToken);

              this.onAccessTokenFetched(r.data.accessToken);
            }).catch(async (_) => {
              toast.error('Fallo la autorización!')
              await MySwal.fire({
                title: 'Error de sesión',
                html: `<p>1. Es posible que la sesión haya caducado</p>
                <p>2. Combinación incorrecta de nombre de usuario y contraseña</p>
                <p>3. El usuario no está activo</p>
                <p>Por favor contacte al administrador para más detalles</p>`,
                icon: 'danger',
                showCloseButton: false,
                showCancelButton: false,
                confirmButtonText: 'Cerrar Sesión',
                customClass: {
                  confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false,
                allowEscapeKey: false,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/login");
                }
              })
            });
          }
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken.replaceAll('"', '')}`;
              resolve(this.axios(originalRequest));
            });
          });
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  getUsers() {
    return axios.get(this.jwtConfig.usersEndpoint);
  }

  getCompanies() {
    return axios.get(this.jwtConfig.companiesEndpoint);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  getTransactions(filters) {
    return axios.get(`${this.jwtConfig.transactionsEndpoint}/dte-list`, { params: filters })
  }
}
