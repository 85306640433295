export const data = [
  {
    company: 'sociedad',
    personType: 'persona 01',
    nit: '123456789',
    nrc: '123456789',
    admin: "Administrador",
    service: '01',
    serviceDigital: '02',
    isActive: true
  }
]