export let businessDatadb =
  {
    id: 1,
    clientName: "techy we",
    company: "Technologies Consultancy and Services, S.A. de C.V.",
    email: 'contabilidad@techy-we.com',
    incomingEmail: 'contabilidad@techy-we.com',
    phoneNumber: '+50378592291',
    nit: '0614-300919-101-4',
    nrc: '280341-5',
    department: { value: '05', label: 'La Libertad', id: 19 },
    municipality: {id: 542, value: "23", label: "LA LIBERTAD NORTE"},
    address: 'Calle Teotl sda. 3, Res. Cumbres de la Esmeralda, # 5, Antgo. Cuscatlán',
    largeConsumer: { value: '04', label: 'Otro', id: "1075" },
    economicActivity: {
        id: 758,
        value: "62090",
        label: "Otras actividades de tecnología de información y servicios de computadora"},
    TypeOffer: {value: "ambos", label: "Ambos"},
    typeEstablishment: {value: "FE", label: "FE"}
  }

export const addUser = (user) => {
  const newId = businessDatadb.length ? businessDatadb[businessDatadb.length - 1].id + 1 : 1;
  const newUser = { id: newId, ...user };
  businessDatadb.push(newUser);
  return newUser;
};

export const updateUser = (updatedBusiness) => {
  // Implementa la lógica para actualizar el usuario en la base de datos ficticia
  // Por ejemplo, puedes reemplazar el usuario en el array `clientsdb`
  console.log("updatedBusiness: ", updatedBusiness)
  businessDatadb = updatedBusiness
  console.log("businessDatadb: ", businessDatadb)

  return businessDatadb;
}

export const deleteUser = (id) => {
  const index = businessDatadb.findIndex(client => client.id === id);
  if (index !== -1) {
    businessDatadb.splice(index, 1);
  }

  return businessDatadb;
}