import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { sendFiltersAdapter } from './adapters'
import axios from 'axios'
import { users } from './db/users'
import toast from 'react-hot-toast'

const REACT_APP_API_QA_URL = process.env.REACT_APP_API_SERVICE_URL || "https://firma.rubridoc.com"

const request = async (url, method, headers, data) => {
  // with fetch
  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(data)
  }).then(response => response.json())
  return response
}


const getTemporalToken = async (user) => {
  const response = await request(`${REACT_APP_API_QA_URL}/api/auth/login`, 'POST', {
    'Content-Type': 'application/json'
  }, {
    userName: user.userName,
    password: user.password
  })

  return response?.access_token
}

export const getTransactions = createAsyncThunk('appInvoice/getTransactions', async ({ filters, page = 0, size = 20, signal }, { rejectWithValue }) => {
  const userName = JSON.parse(localStorage.getItem("userBilligData"))
  const cleanFilters = sendFiltersAdapter(filters)
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      mode: 'no-cors',
      signal
    })
    const getTransactions = await axiosInstance.get('/api/transaction/dte-list', {
      params: { ...cleanFilters, page, size, isSuccessful: 1 }
    })
    console.log("getTransactions?.data", getTransactions?.data?.content)
    return {
      data: getTransactions?.data?.content,
      totalPages: getTransactions?.data?.totalPages,
    }

  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message)
    }
    return rejectWithValue(error.response?.data || error.message)
  }
})

export const downloadInvoiceJSON = createAsyncThunk('appInvoice/dowloadInvoiceJSON', async (params) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)

  if (!user) throw new Error('User not found')

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      mode: 'no-cors'
    })
    const downloadInvoiceJSON = await axiosInstance.get(`/api/transaction/json/${user?.id}/${params.id}/${params.emissionDate}`)
    return downloadInvoiceJSON.data
  } catch (error) {
    if (error?.response?.status === 404) {
      return { error: true, payload: { rubridocTransactionStatusMsj: `No se encontró el archivo ${params.generationCode}` } }
    }
    throw error
  }
})

export const downloadInvoices = createAsyncThunk('appInvoice/downloadInvoices', async (params) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)

  if (!user) throw new Error('User not found')

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf'
      },
      // mode: 'no-cors'
    })
    const downloadInvoices = await axiosInstance.get(`/api/transaction/${user?.id}/${params.id}/${params.emissionDate}`, {
      responseType: 'blob'
    })
    return downloadInvoices.data
  } catch (error) {
    if (error?.response?.status === 404) {
      return { error: true, payload: { rubridocTransactionStatusMsj: `No se encontró el archivo ${params.generationCode}` } }
    }
    throw error
  }
})

const initialState = {
  data: [],
  isPending: false,
}

export const appInvoiceSlice = createSlice({
  initialState,
  name: 'appInvoice',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    }
  },
  extraReducers: builder =>
  builder.addCase(getTransactions.fulfilled, (state, action) => {
    state.data = action.payload
    state.isPending = false
  })
  .addCase(getTransactions.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getTransactions.rejected, (state, action) => {
    state.isPending = false
  })
})

export const { setInitialData } = appInvoiceSlice.actions

export default appInvoiceSlice.reducer