export const users = [
  {
    id: 1,
    companyId: 'rubridoc', // 4
    userName: 'rubridoc',
    password: 'Temporal123!',
    userId: 1
  },
  {
    id: 2,
    companyId: 'qa',
    userName: 'quality_assurance_usr_api',
    password: 's6rq7BQGpdQKCGmmsnjpkf'
  },
  {
    id: 3,
    companyId: 'leasing',
    userName: 'leasing_usr_api',
    password: 's6rq7BQGpdQKCGmmsnjpkf'
  },
  // Credenciales Techy-We
  {
    id: 4,
    companyId: 'techy_we',
    userName: 'techy_we', //techy_we
    password: 'Temporal123!',
    userId: 4
  }
]

export const clientsdb = [
  {
    id: 1,
    clientCode: '12345',
    codeType: 'dui',
    dteType: '01',
    company: 'Empresa A',
    receiverEmail: 'empresaA@example.com',
    receiverPhoneNumber: '123-456-7890',
    receiverName: 'Juan Pérez',
    endDate: null,
    tradeName: 'Trade Name A',
    EconomicActivity: 'Actividad A',
    NIT: '11111111111111111',
    NRC: '56789012345678',
    department: 'Department A',
    municipality: 'Municipality A',
    address: 'Address A'
  },
  {
    id: 2,
    clientCode: '67890',
    codeType: 'nit',
    dteType: '02',
    company: 'Empresa B',
    receiverEmail: 'empresaB@example.com',
    receiverPhoneNumber: '987-654-3210',
    receiverName: 'María López',
    endDate: null,
    tradeName: 'Trade Name B',
    EconomicActivity: 'Actividad B',
    NIT: '56789012345678',
    NRC: '90123456789012',
    department: 'Department B',
    municipality: 'Municipality B',
    address: 'Address B'
  }
  // Add more users as needed
];
