import { createAsyncThunk } from '@reduxjs/toolkit';

import { addClientRequest, deleteClientRequest, editClientRequest, getClientsRequest, getTemporalToken } from '../../../../billing-api';
import { users } from '../../invoice-issuance/db/users';

//const userLogin = {companyId: 'rubridoc'}
//const user = users.find(user => user.companyId === userLogin.companyId)
//const token = await getTemporalToken(user);

export const getClients = createAsyncThunk('appInvoice/getClients', async (id) => {
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName.username}
    const user = users.find(user => user.companyId === userLogin.companyId)
    const token = await getTemporalToken(user);

    return await getClientsRequest(id, token);
});

export const addClient = createAsyncThunk('appInvoice/addClient', async (client) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);
  
  return await addClientRequest(client, token);
});

export const editClient = createAsyncThunk('appInvoice/editClient', async (client) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await editClientRequest(client, token);
});

export const deleteClient = createAsyncThunk('appInvoice/deleteClient', async (clientId) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await deleteClientRequest(clientId, token);
});