// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waves-effect {
  position: relative;
  overflow: hidden;
}
.waves-effect .waves-ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  animation: ripple-effect 0.7s ease;
}

@keyframes ripple-effect {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/@core/components/ripple-button/ripple-button.scss"],"names":[],"mappings":"AAMA;EACE,kBAAA;EACA,gBAAA;AALF;AAME;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,oCAAA;EACA,kCAAA;AAJJ;;AAQA;EACE;IACE,UAAA;EALF;EAOA;IACE,UAAA;EALF;EAOA;IACE,WAAA;IACA,oBAAA;IACA,UAAA;EALF;AACF","sourcesContent":["// Component: Ripple Button\n// ========================================================================\n\n@import '../../scss/base/bootstrap-extended/include';\n@import '../../scss/base/components/include';\n\n.waves-effect {\n  position: relative;\n  overflow: hidden;\n  .waves-ripple {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    opacity: 0;\n    width: 0;\n    height: 0;\n    border-radius: 50%;\n    background: rgba($color: $white, $alpha: 0.2);\n    animation: ripple-effect 0.7s ease;\n  }\n}\n\n@keyframes ripple-effect {\n  0% {\n    opacity: 0;\n  }\n  25% {\n    opacity: 1;\n  }\n  100% {\n    width: 200%;\n    padding-bottom: 200%;\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
