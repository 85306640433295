import { createAsyncThunk } from '@reduxjs/toolkit';

import { addClientRequest, createProductsRequest, deleteClientRequest, deleteProductRequest, editClientRequest, getClientsRequest, getProductsRequest, getTemporalToken, updateProductRequest } from '../../../../billing-api';
import { users } from '../../invoice-issuance/db/users';

// const userName = await JSON.parse(localStorage.getItem("userBilligData"))
// const userLogin = {companyId: userName?.username}
// const user = users.find(user => user.companyId === userLogin?.companyId)
// //const token = await getTemporalToken(user);

export const getProducts = createAsyncThunk('appInvoice/getProducts', async () => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await getProductsRequest(token);
});

export const createProducts = createAsyncThunk('appInvoice/createProducts', async (product) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await createProductsRequest(product, token);
});

export const updateProduct = createAsyncThunk('appInvoice/updateProduct', async (product) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await updateProductRequest(product, token);
});

export const deleteProduct = createAsyncThunk('appInvoice/deleteProduct', async (productId) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName.username}
  const user = users.find(user => user.companyId === userLogin.companyId)
  const token = await getTemporalToken(user);

  return await deleteProductRequest(productId, token);
});