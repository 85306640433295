import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
    getEconomicActivitiesRequest,
    getCitiesRequest,
    getDepartmentsRequest,
    getDocumentTypeRequest,
    getTemporalToken, 
    submitMHInvalidateTransactionRequest, 
    submitMHTransactionRequest, 
    getMeasurementUnitRequest,
    getSaleTypeRequest,
    getPaymentMethodRequest,
    getMHTransactionsRequest,
    getProductTypesRequest,
    getDocumentsRequest,
    getDocumentProductsRequest,
    getDocumentProductsJsonRequest,
    getCountriesRequest,
    getOfferTypesRequest,
    submitMHContingencyRequest} from "../../../../billing-api";
import { users } from '../db/users';

const userData = localStorage.getItem('userData');
let company = ''
// Verifica si el objeto existe
if (userData) {
  // Convertir la cadena JSON a un objeto JavaScript
  const parsedData = JSON.parse(userData);
  
  // Acceder al campo 'compañia'
  company = parsedData.Company;

  console.log('Compañía:', company);
} else {
  console.log('No se encontró información en localStorage.');
}



export const submitMHTransaction = createAsyncThunk('appInvoice/submitMHTransaction', async (data) => {
    //const token = await getTemporalToken(user);
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);
    return await submitMHTransactionRequest(data, token, user.userId);
  });

export const submitMHInvalidateDte = createAsyncThunk('appInvoice/submitMHInvalidateDte', async (data) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await submitMHInvalidateTransactionRequest(data, token, user.userId);
});

export const submitMHContingencyDte = createAsyncThunk('appInvoice/submitMHContingencyDte', async (data) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await submitMHContingencyRequest(data, token, user.userId);
});

export const getDepartments = createAsyncThunk('appInvoice/getDepartments', async () => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)

   const token = await getTemporalToken(user);

    return await getDepartmentsRequest(token);
});

export const getCountries = createAsyncThunk('appInvoice/getCountries', async () => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
   return await getCountriesRequest(token);
});

export const getCities = createAsyncThunk('appInvoice/getCities', async (deparmentCode) => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await getCitiesRequest(deparmentCode, token);
});

export const getOfferTypes = createAsyncThunk('appInvoice/getOfferTypes', async () => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
   return await getOfferTypesRequest(token);
});

export const getEconomicActivities = createAsyncThunk('appInvoice/getEconomicActivities', async () => {
    //const token = await getTemporalToken(user);
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);
    return await getEconomicActivitiesRequest(token);
  });

export const getDocumentTypes = createAsyncThunk('appInvoice/getDocumentTypes', async () => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await getDocumentTypeRequest(token);
});

export const getMeasurementUnit = createAsyncThunk('appInvoice/getMeasurementUnit', async () => {
    //const token = await getTemporalToken(user);
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);
    return await getMeasurementUnitRequest(token);
  });

export const getProductTypes = createAsyncThunk('appInvoice/getProductTypes', async () => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await getProductTypesRequest(token);
});

export const getSaleTypes = createAsyncThunk('appInvoice/getSaleTypes', async () => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await getSaleTypeRequest(token);
});

export const getPaymentMethod = createAsyncThunk('appInvoice/getPaymentMethod', async () => {
    //const token = await getTemporalToken(user);
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);

    return await getPaymentMethodRequest(token);
});

export const getMHTransaction = createAsyncThunk('appInvoice/getMHTransaction', async (dteId) => {
  //const token = await getTemporalToken(user);
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);

  return await getMHTransactionsRequest(dteId, token);
});

export const getDocuments = createAsyncThunk('appInvoice/getDocuments', async ({documentTypeId, clientDocumentType, documentNumber}) => {
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
  const userLogin = {companyId: userName?.username}
  const user = users.find(user => user.companyId === userLogin?.companyId)
  const token = await getTemporalToken(user);
  return await getDocumentsRequest(token, documentTypeId, user.userId, clientDocumentType, documentNumber);
});

export const setProductsCreditList = createAsyncThunk('appInvoice/setProductsCreditList', async ({createdAt, generationCode}) => {

  try {
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);
    return await getDocumentProductsRequest(token, user.userId, generationCode, createdAt);
  } catch (error) {
    console.error("Error in setProductsCreditList thunk: ", error);
    return rejectWithValue(error.message);
  }
})

export const setProductsCreditJsonList = createAsyncThunk('appInvoice/setProductsCreditJsonList', async ({docId}) => {

  try {
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName?.username}
    const user = users.find(user => user.companyId === userLogin?.companyId)
    const token = await getTemporalToken(user);
    return await getDocumentProductsJsonRequest(token, docId);
  } catch (error) {
    console.error("Error in setProductsCreditList thunk: ", error);
    return rejectWithValue(error.message);
  }
})

export const setRelatedDoc = createAsyncThunk('appInvoice/setRelatedDoc', async (relatedDoc) => {
  console.log("relatedDoc: ", relatedDoc)
  return relatedDoc
})