//import { transformMHProductsToLocal } from "../utility/utils";

const BASE_URL = process.env.REACT_APP_API_SERVICE_URL || "https://firma.rubridoc.com/api"

export function transformMHProductsToLocal(items) {
  return items.map(item => ({
    id: item.numItem, // Correspondencia con numItem
    prodType: { value: 'Producto', label: 'Producto' }, // Valor fijo como ejemplo
    productCode: item.codigo, // Correspondencia con codigo
    productName: item.descripcion, // Correspondencia con descripcion
    quantity: item.cantidad, // Correspondencia con cantidad
    description: "new", // Valor fijo
    unitOfMeasure: { value: '01', label: 'Metro' },
    TypeOfSale: { id: "34", label: "Servicios", value: "2"},
    price: item.precioUni, // Correspondencia con precioUni
    discount: item.montoDescu,
    accept: true,
    isActive: true
  }));
}

export const request = async (url, method, headers, data) => {
    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(data),
    });
    return response.json();
  };


export const getTemporalToken = async (user) => {
  console.log("Entra a getTemporalToken: ", user)
  const response = await request(`${BASE_URL}/auth/login`, 'POST', {
    'Content-Type': 'application/json'
  }, {
    userName: user?.userName,
    password: user?.password
  });

  return response?.access_token
};

export const billingLogin = async (user) => {
  console.log("USER LLEGA: ", user)
  const response = await request(`${BASE_URL}/auth/login`, 'POST', {
    'Content-Type': 'application/json'
  }, {
    userName: user?.userName,
    password: user?.password
  });

  return response
};

export const submitMHTransactionRequest = async (data, token, companyId) => {
  return await request(`${BASE_URL}/transaction/send-mhh/${companyId}`, 'POST', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, data);
};

export const submitMHInvalidateTransactionRequest = async (data, token, companyId) => {
    return await request(`${BASE_URL}/transaction/invalidateDte/${companyId}`, 'POST', {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }, data);
};

//

export const submitMHContingencyRequest = async (data, token, companyId) => {
  return await request(`${BASE_URL}/transaction/contingency/${companyId}`, 'POST', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, data);
};

export const getCountriesRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/countries`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getDepartmentsRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/departments`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getCitiesRequest = async (departmentCode, token) => {
  return await request(`${BASE_URL}/catalog/cities/${departmentCode}`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getOfferTypesRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/offer-type`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getEconomicActivitiesRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/economic-activity`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getDocumentTypeRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/document-type`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getMeasurementUnitRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/measurement-unit`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getSaleTypeRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/sale-type`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getPaymentMethodRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/payment-method`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};


/* Clients */

export const getClientsRequest = async (id, token) => {
  return await request(`${BASE_URL}/client/${id ? id : ''}`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const addClientRequest = async (client, token) => {
  return await request(`${BASE_URL}/client`, 'POST', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, client);
};

export const editClientRequest = async (client, token) => {
  const response = await request(`${BASE_URL}/client/${client.id}`, 'PUT', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, client);

  return response;
};

export const deleteClientRequest = async (clientId, token) => {
  return await request(`${BASE_URL}/client/${clientId}`, 'DELETE', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getMHTransactionsRequest = async (dteId, token) => {
  return await request(`${BASE_URL}/transaction/dte/${dteId}/1`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};


/*----------------------------------------- CRUD Productos ------------------------------------------------*/

export const getProductsRequest = async (token) => {
  return await request(`${BASE_URL}/productService/`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const createProductsRequest = async (product, token) => {
  return await request(`${BASE_URL}/productService/`, 'POST', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, product);
};

export const updateProductRequest = async (product, token) => {
  return await request(`${BASE_URL}/productService/${product.id}`, 'PUT', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }, product);
};

export const deleteProductRequest = async (productId, token) => {
  return await request(`${BASE_URL}/productService/${productId}`, 'DELETE', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getProductTypesRequest = async (token) => {
  return await request(`${BASE_URL}/catalog/product-type`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};


export const getDocumentsRequest = async (token, documentTypeId, companyId, clientDocumentType, documentNumber) => {
  return await request(`${BASE_URL}/transaction/dte/${documentTypeId}/${companyId}?clientType=${clientDocumentType}&clientDocument=${documentNumber}&isSucessful=1`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};

export const getDocumentProductsRequest = async (token, companyId, generationCode, createdAt) => {
  const documentProductList = await request(`${BASE_URL}/transaction/json/${companyId}/${generationCode}/${createdAt}`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  const documentBody = JSON.parse(documentProductList)
  const transformData = transformMHProductsToLocal(documentBody.cuerpoDocumento)
  //se debe implementar una función que convierta el contenido del componente en una lista compatible con el frontend
  // debe calcular los valores de precio unitario y gravado acorde al documento emitido
  return {
    transformData,
    relatedDoc: {...documentBody.identificacion, montoIva: documentBody.resumen.totalIva}
  }
};

export const getDocumentProductsJsonRequest = async (token, docId) => {
  const documentProductList = await request(`${BASE_URL}/transaction/file/json/${docId}`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  const documentBody = JSON.parse(documentProductList)
  console.log("documentProductList: ", JSON.parse(documentProductList).cuerpoDocumento)
  const transformData = transformMHProductsToLocal(documentBody.cuerpoDocumento)
  console.log("transformData: ", transformData)
  console.log("documentBody: ", documentBody)
  //se debe implementar una función que convierta el contenido del componente en una lista compatible con el frontend
  // debe calcular los valores de precio unitario y gravado acorde al documento emitido
  return {
    transformData,
    relatedDoc: {...documentBody.identificacion, montoIva: documentBody.resumen.totalIva}
  }
};

export const getIssuerRequest = async (token, userId) => {
  return await request(`${BASE_URL}/issuer/${userId}`, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });
};
