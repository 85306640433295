export const clientsdb = [
  {
    id: 1,
    clientCode: '123456789',
    codeType: 'dui',
    dteType: '01',
    company: 'Empresa A',
    receiverEmail: 'empresaA@example.com',
    receiverPhoneNumber: '12345678',
    receiverName: 'juan perez',
    endDate: null,
    tradeName: 'Trade Name A',
    EconomicActivity: { value: "01111", label: "Cultivo de cereales excepto arroz y para forrajes" },
    NIT: '123456789',
    DUI: '123456789',
    NRC: '56789012345678',
    department: { value: '01', label: 'Ahuachapán' },
    municipality: { value: '01', label: 'AHUACHAPÁN' },
    address: 'Address A',
    typeEntity: { label: 'Natural', value: 'N' }
  },
  {
    id: 2,
    clientCode: '67890',
    codeType: 'nit',
    dteType: '02',
    company: 'Empresa B',
    receiverEmail: 'empresaB@example.com',
    receiverPhoneNumber: '12345678',
    receiverName: 'maria lopez',
    endDate: null,
    tradeName: 'Trade Name B',
    EconomicActivity: { value: "01111", label: "Cultivo de cereales excepto arroz y para forrajes" },
    NIT: '56789012345678',
    DUI: '56789012345678',
    NRC: '90123456789012',
    department: { value: '01', label: 'Ahuachapán' },
    municipality: { value: '01', label: 'AHUACHAPÁN' },
    address: 'Address B',
    typeEntity: { label: 'Jurídica', value: 'J' }

  }
  // Add more users as needed
];

export const productServicedb = [
  {
    id: 3.5,
    prodType:{ value: 'Producto', label: 'Producto' },
    productCode: '0003',
    productName: 'Monitor',
    quantity: 20,
    description: "new",
    unitOfMeasure: { value: 'Metro', label: 'Metro' },
    TypeOfSale: 'Bienes',
    price: 300,
    discount: 50,
    accept: true,
    isActive: true
  }
];

export const addUser = (user) => {
  const newId = productServicedb.length ? productServicedb[productServicedb.length - 1].id + 1 : 1;
  const newUser = { id: newId, ...user };
  productServicedb.push(newUser);
  return newUser;
};

export const updateUser = (updatedUser) => {
  // Implementa la lógica para actualizar el usuario en la base de datos ficticia
  // Por ejemplo, puedes reemplazar el usuario en el array `clientsdb`
  const index = productServicedb.findIndex(user => user.id === updatedUser.id);
  if (index !== -1) {
    productServicedb[index] = updatedUser;
  }
  return updatedUser;
}

export const deleteUser = (id) => {
  const index = productServicedb.findIndex(client => client.id === id);
  if (index !== -1) {
    productServicedb.splice(index, 1);
  }

  return productServicedb;
}