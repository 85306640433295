import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'
import { clientsdb, addUser, updateUser, deleteUser, businessDatadb } from './fake-data'
import { getIssuerInfo } from './thunks'


export const getData = createAsyncThunk('businessManager/getData', async (filters) => {

  return businessDatadb
})

export const addUserAction = createAsyncThunk('businessManager/addUser', async (user) => {
  const newUser = addUser(user);
  return newUser;
});

export const editAction = createAsyncThunk('businessManager/editBusiness', async (user) => {

  const updatedUser = updateUser(user);

  return updatedUser;
});

export const deleteUserAction = createAsyncThunk('businessManager/deleteUser', async (id) => {
  const updatedData = deleteUser(id);
  return id;
});

export const checkIfUserExists = (productCode) => {
  return businessDatadb.some(item => 
    (item.productCode !== "" && item.productCode === productCode));
};


const initialState = {
  data: null,
  isPending: false,
  modal: { logo: { open: false } }
}

export const businessManagerSlice = createSlice({
  initialState,
  name: 'businessManager',
  reducers: {
    setInitialData: (state) => {
      state.data = {}
    },
    setOpenModal: (state, action) => {
      const { open } = action.payload
      state.modal.logo.open = open
    }
  },
  extraReducers: builder =>
  builder.addCase(getData.fulfilled, (state, action) => {
    state.data = action.payload
    state.isPending = false
  })
  .addCase(getData.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getData.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(addUserAction.fulfilled, (state, action) => {
    state.data.push(action.payload);
  })
  .addCase(editAction.fulfilled, (state, action) => {
    const updatedUser = action.payload;
    console.log("updatedUser: STORE ", updatedUser)
    state.data = updatedUser;

  })
  .addCase(deleteUserAction.fulfilled, (state, action) => {
    const id = action.payload;
    state.data = state.data.filter(user => user.id !== id);
  })
  .addCase(getIssuerInfo.fulfilled, (state, action) => {
    state.data = action.payload;
  })
})

export const { setInitialData, setOpenModal } = businessManagerSlice.actions

export default businessManagerSlice.reducer