import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { sendFiltersAdapter } from './adapters'
import axios from 'axios'
import { users } from './db/users'
import toast from 'react-hot-toast'
import { getTemporalToken, submitMHTransactionRequest } from '../../../billing-api'
import { getCities, getCountries, getDepartments, getDocuments, getDocumentTypes, getEconomicActivities, getMeasurementUnit, getMHTransaction, getOfferTypes, getPaymentMethod, getProductTypes, getSaleTypes, setProductsCreditJsonList, setProductsCreditList, setRelatedDoc, submitMHContingencyDte, submitMHInvalidateDte, submitMHTransaction } from './thunks'

const REACT_APP_API_QA_URL = process.env.REACT_APP_API_SERVICE_URL || "https://firma.rubridoc.com/api" //"https://api-is-qa.rubridoc.com"


export const getTransactions = createAsyncThunk('appInvoice/getTransactions', async (filters) => {
  const cleanFilters = sendFiltersAdapter(filters)
  const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName.username}
    const user = users.find(user => user.companyId === userLogin.companyId)

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      mode: 'no-cors'
    })
    const getTransactions = await axiosInstance.get('/api/transaction/dte-list', {
      params: { ...cleanFilters, size: 2000, isSuccessful: 0 }
    })
    return getTransactions?.data?.content

  } catch (error) {
    console.log(error)
    return error
  }
})

export const downloadInvoiceJSON = createAsyncThunk('appInvoice/dowloadInvoiceJSON', async (params) => {
  const userLogin = {companyId: 'rubridoc'}
  const user = users.find(user => user.companyId === userLogin.companyId)

  if (!user) throw new Error('User not found')

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      mode: 'no-cors'
    })
    const downloadInvoiceJSON = await axiosInstance.get(`/api/transaction/json-mh/${user?.id}/${params.generationCode}/${params.emissionDate}`)
    return downloadInvoiceJSON.data
  } catch (error) {
    if (error?.response?.status === 404) {
      return { error: true, payload: { rubridocTransactionStatusMsj: `No se encontró el archivo ${params.generationCode}` } }
    }
    throw error
  }
})

export const downloadInvoiceJsonMh = createAsyncThunk('appInvoice/dowloadInvoiceJSON', async (params) => {
  const userLogin = {companyId: 'rubridoc'}
  const user = users.find(user => user.companyId === userLogin.companyId)

  if (!user) throw new Error('User not found')

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      mode: 'no-cors'
    })
    const downloadInvoiceJSON = await axiosInstance.get(`/api/transaction/json/${user?.id}/${params.generationCode}/${params.emissionDate}`)
    return downloadInvoiceJSON.data
  } catch (error) {
    if (error?.response?.status === 404) {
      return { error: true, payload: { rubridocTransactionStatusMsj: `No se encontró el archivo ${params.generationCode}` } }
    }
    throw error
  }
})

export const downloadInvoices = createAsyncThunk('appInvoice/downloadInvoices', async (params) => {
  const userLogin = {companyId: 'rubridoc'}
  const user = users.find(user => user.companyId === userLogin.companyId)

  if (!user) throw new Error('User not found')

  try {
    const token = await getTemporalToken(user)
    const axiosInstance = axios.create({
      baseURL: `${REACT_APP_API_QA_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf'
      },
      // mode: 'no-cors'
    })
    const downloadInvoices = await axiosInstance.get(`/api/transaction/${user?.id}/${params.generationCode}/${params.emissionDate}`, {
      responseType: 'blob'
    })
    return downloadInvoices.data
  } catch (error) {
    if (error?.response?.status === 404) {
      return { error: true, payload: { rubridocTransactionStatusMsj: `No se encontró el archivo ${params.generationCode}` } }
    }
    throw error
  }
})

export const setUserAction = createAsyncThunk('appInvoice/setUser', async (user) => {

    return user

})

export const setProductsCreditAction = createAsyncThunk('appInvoice/setProducts', async (products) => {

  return products

})

export const setDatasBillAction = createAsyncThunk('appInvoice/setDataBill', async (billData) => {

  return billData;
});

const initialState = {
  receptor: {},
  products: [],
  isPending: false,
  bill: {
    products: [],
    total: 0
  },
  identificacion: {},
    emisor: {
      nit: "06143005191014",
      nrc: "280341-5",
      nombre: "Technologies Consultancy and Services, S.A. de C.V.",
      codActividad: "45100",
      descActividad: "Venta de vehículos automotores",
      nombreComercial: "TechyWe",
      tipoEstablecimiento: "01",
      direccion: {
          departamento: "05",
          municipio: "23",
          complemento: "Calle Teotl sda. 3, Res. Cumbres de la Esmeralda, # 5, Antgo. Cuscatlán, La Libertad Este, La Libertad"
      },
      telefono: "78592291",
      correo: "contabilidad@techy-we.com",
      codEstableMH: "M001",
      codEstable: "M001",
      codPuntoVentaMH: "M001",
      codPuntoVenta: "P001",
      tipoItemExpor: 2, //se quita para credito fiscal
      /* no aplica factura exportacion */
      //regimen: "EX1.10",
      //recintoFiscal: "02"
  },
  cuerpoDocumento:[],
  resumen: {},
  countries: [],
  departments: [],
  cities: [],
  economicActivities: [],
  documentTypes: [],
  measurementUnits: [],
  productTypes: [],
  saleTypes: [],
  paymentMethods: [],
  documentList: [],
  offerTypes: [],
  relatedDoc: null
  

}

export const appInvoiceSlice = createSlice({
  initialState,
  name: 'appInvoice',
  reducers: {
    setInitialData: (state) => {
      state.receptor = {},
      state.products = []
    }
  },
  extraReducers: builder =>
  builder.addCase(getTransactions.fulfilled, (state, action) => {
    state.receptor = action.payload
    state.isPending = false
  })
  .addCase(getTransactions.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(getTransactions.rejected, (state, action) => {
    state.isPending = false
  })
    /* Envió de transacciones a MH */
  .addCase(submitMHTransaction.fulfilled, (state, action) => {
    state.isPending = false
  })
  .addCase(submitMHTransaction.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(submitMHTransaction.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(submitMHInvalidateDte.fulfilled, (state, action) => {
    state.isPending = false
  })
  .addCase(submitMHInvalidateDte.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(submitMHInvalidateDte.rejected, (state, action) => {
    state.isPending = false
  })
  .addCase(submitMHContingencyDte.fulfilled, (state, action) => {
    state.isPending = false
  })
  .addCase(submitMHContingencyDte.pending, (state, action) => {
    state.isPending = true
  })
  .addCase(submitMHContingencyDte.rejected, (state, action) => {
    state.isPending = false
  })

  /* Catalogos */
  
  .addCase(getOfferTypes.fulfilled, (state, action) => {
    state.isPending = false
    state.offerTypes = action.payload
  })
  .addCase(getDepartments.fulfilled, (state, action) => {
    state.isPending = false
    state.departments = action.payload
  })
  .addCase(getCountries.fulfilled, (state, action) => {
    state.isPending = false
    state.countries = action.payload
  })
  .addCase(getCities.fulfilled, (state, action) => {
    state.isPending = false
    state.cities = action.payload
  })
  .addCase(getEconomicActivities.fulfilled, (state, action) => {
    state.isPending = false
    state.economicActivities = action.payload
  })
  .addCase(getDocumentTypes.fulfilled, (state, action) => {
    state.isPending = false
    state.documentTypes = action.payload
  })
  .addCase(getMeasurementUnit.fulfilled, (state, action) => {
    state.isPending = false
    state.measurementUnits = action.payload
  })
  .addCase(getProductTypes.fulfilled, (state, action) => {
    state.isPending = false
    state.productTypes = action.payload
  })
  .addCase(getSaleTypes.fulfilled, (state, action) => {
    state.isPending = false
    state.saleTypes = action.payload
  })
  .addCase(getPaymentMethod.fulfilled, (state, action) => {
    state.isPending = false
    state.paymentMethods = action.payload
  })
  .addCase(getMHTransaction.fulfilled, (state, action) => {
    state.isPending = false
    state.paymentMethods = action.payload
  })
  .addCase(getDocuments.fulfilled, (state, action) => {
    state.isPending = false
    state.documentList = action.payload?.content
  })

  /* Administracion de usuarios */
  .addCase(setUserAction.fulfilled, (state, action) => {
    const user = action.payload;
    state.receptor = user;
  })
  /* Administracion de productos y factura */
  .addCase(setProductsCreditAction.fulfilled, (state, action) => {
    const products = action.payload;
    state.products = products;
  })
  .addCase(setDatasBillAction.fulfilled, (state, action) => {
    const billData = action.payload;
    state.bill = billData;
  })
  .addCase(setProductsCreditList.rejected, (state, action) => {
    state.isPending = false
    state.products = [];
  })
  .addCase(setProductsCreditList.fulfilled, (state, action) => {
    const products = action.payload?.transformData;
    state.products = products;
    state.relatedDoc = action.payload.relatedDoc;

  })
  .addCase(setProductsCreditJsonList.rejected, (state, action) => {
    state.isPending = false
    state.products = [];
  })
  .addCase(setProductsCreditJsonList.fulfilled, (state, action) => {
    const products = action.payload?.transformData;
    state.products = products;
    state.relatedDoc = action.payload.relatedDoc;

  })
  /*.addCase(setRelatedDoc.fulfilled, (state, action) => {
    console.log("action.payload: ", action.payload)
    state.relatedDoc = action.payload;
  })*/
})

export const { setInitialData } = appInvoiceSlice.actions

export default appInvoiceSlice.reducer