export const clientsdb = [
  {
    id: 3,
    clientCode: '67890',
    codeType: 'nit',
    dteType: '02',
    company: 'Empresa B',
    receiverEmail: 'contabilidad@techy-we.com',
    receiverPhoneNumber: '12378459',
    receiverName: 'sofia cardenas',
    endDate: null,
    tradeName: 'Trade Name B',
    EconomicActivity: { value: "01111", label: "Cultivo de cereales excepto arroz y para forrajes" },
    economicActivity: { value: "01111", label: "Cultivo de cereales excepto arroz y para forrajes" },
    NIT: '147852356',
    DUI: '14785255-6',
    NRC: '1478523',
    nit: '147852356',
    dui: '14785255-6',
    nrc: '1478523',
    department: { value: '01', label: 'Ahuachapán' },
    municipality: { value: '01', label: 'AHUACHAPÁN' },
    address: 'Address B',
    typeEntity: { label: 'Jurídica', value: 'J' },
    dteType: { value: '11', label: 'Facturas de exportación' },
    largeConsumer: null,
    dteClientType: {value: "nit", label: "NIT"},
    country: {value: "9320", label: "Colombia"}

  }
];

export const addUser = (user) => {
  const newId = clientsdb.length ? clientsdb[clientsdb.length - 1].id + 1 : 1;
  const newUser = { id: newId, ...user };
  clientsdb.push(newUser);
  return newUser;
};

export const updateUser = (updatedUser) => {
  // Implementa la lógica para actualizar el usuario en la base de datos ficticia
  // Por ejemplo, puedes reemplazar el usuario en el array `clientsdb`
  console.log("updatedUser: ", updatedUser)
  const index = clientsdb.findIndex(user => user.id === updatedUser.id);
  console.log("index: ", index)

  if (index !== -1) {
    clientsdb[index] = updatedUser;
  }
  return updatedUser;
}

export const deleteUser = (id) => {
  const index = clientsdb.findIndex(client => client.id === id);
  if (index !== -1) {
    clientsdb.splice(index, 1);
  }

  return clientsdb;
}