import { createAsyncThunk } from '@reduxjs/toolkit';

import { addClientRequest, deleteClientRequest, editClientRequest, getClientsRequest, getIssuerRequest, getTemporalToken } from '../../../../billing-api';
import { users } from '../../invoice-issuance/db/users';

//const user = users.find(user => user.companyId === userLogin.companyId)

export const getIssuerInfo = createAsyncThunk('appInvoice/getIssuerInfo', async () => {
    const userName = await JSON.parse(localStorage.getItem("userBilligData"))
    const userLogin = {companyId: userName.username}
    const user = users.find(user => user.companyId === userLogin.companyId)
    const token = await getTemporalToken(user);

    return await getIssuerRequest(token, user.userId);
});