export const sendFiltersAdapter = (filters) => {
  // console.log(filters, "filters")
  // quitar valores vacios o nulos

  const cleanFilters = Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {})

  if (cleanFilters.startDate) {
    // parsear a 'YYYY-MM-DD'
    cleanFilters.startDate = new Date(cleanFilters.startDate).toISOString().split('T')[0]
  }

  if (cleanFilters.endDate) {
    cleanFilters.endDate = new Date(cleanFilters.endDate).toISOString().split('T')[0]
  }

  // console.log(cleanFilters, "cleanFilters")
  return cleanFilters
}